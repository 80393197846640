import { emptyFeatureValue, FeatureValue, ModelWithRelevantFeature } from './feature';

export interface Affix {
  triggerId?: number;
  triggerFeatureDescription?: string;
  inflectionRules?: ListInflectionPattern[];
}

export interface AffixFilterSettings {
  name: 'SAMPLE' | 'APPLICABLE_FOR' | 'ASSIGNS',
  value: string,
  label: string
}

export interface FamilyWithDescription {
  id: number; description: string; warn?: boolean;
}

export interface AffixTrigger extends ModelWithRelevantFeature {
  id?: number;
  feature?: FeatureValue;
  tagging?: boolean;
  handleMWEs?: boolean;
  multiwordPhraseFamilies?: FamilyWithDescription[];
  note?: string;
}

/**
 * An inflection pattern displayed in the list view (NOT when editing)
 */
export interface ListInflectionPattern extends ModelWithRelevantFeature {
  inflectionRuleId?: number | string;
  required?: string; // Required features	The comma-delimited list of descriptions of the required features
  assigned?: string; // Assigned features	The comma-delimited list of descriptions of the assigned features
  incompatible?: string; // Incompatible features	The comma-delimited list of descriptions of the incompatible features
  phonemeCompatibilityMode?: string; // Phonemes are compatible?	If the phonemes are compatible: V*. If not compatible: *X. If both (e.g. in one affix they are compatible, in the other they are not): VX
  phonemeCompatibilityList?: string; // (In)Compatible phoneme list	Comma-delimited list of phonemes.
  propagate?: any; // propagate?	Is the inflection rule to be combined with other inflection rules.
  sample?: any; // Sample	Sample string demonstrating how the affix is applied on a word.
  lastResort?: boolean; // whether the generated forms will be last resort only
  placements?: string[]; //a calculated property show list of unique ConcatenationPlacement values of the members in the _affixes list.
  assignedIdList?: string;
  requiredIdList?: string;
  compatibilitySetDescription?: string;
  testFailures?: string; //morphology test results
  hasTests?: boolean; //whether contain a test
}

/**
 * An affix listed in the inflection pattern edit page.
 */
export interface ListedInflectionPatternAffix {
  requestId?: string;
  id?: number | string;
  advancedCriteriaId: number;
  affix: string;
  concatenationPlacement: number;
  allowOverwrite: boolean;
  charactersToDelete?: number;
  reduplicationStep?: number;
  removeReduplicated?: boolean;
  targetRegex?: string;
  legacyId?: number;
  notes: string;
  remainsInStemmedForm?: boolean;
  isPrefix: boolean;
}

/**
 * An affix object edited in the affix edit page. 
 * Basically an expanded version of ListedInflectionPatternAffix.
 */
export interface EditedInflectionPatternAffix extends ListedInflectionPatternAffix {
  advancedCriteriaDescription: string;
  assigned: FeatureValue[];
  assignedDescriptions: string;
  incompatible: FeatureValue[];
  incompatibleDescriptions: string;
  required: FeatureValue[];
  requiredDescriptions: string;
  phoneticCompatibilityList?: string;
  isPhonemeCompatible?: boolean;
}

/**
 * A full inflection pattern structure (used for editing).
 */
export interface InflectionPattern extends ModelWithRelevantFeature {
  id?: number | string;
  propagate: string;
  noninflectable: boolean;
  preferNonLemmaBase: boolean;
  sample?: string;
  note?: string;
  affixes: EditedInflectionPatternAffix[];
  triggerId?: number;
  allowMWE?: boolean;
  lastResort?: boolean;
}

export const newInflectionTrigger: AffixTrigger = {
  feature: emptyFeatureValue,
  tagging: false,
  handleMWEs: false,
  note: ''
};

export const newTaggingTrigger: AffixTrigger = {
  feature: emptyFeatureValue,
  tagging: true,
  handleMWEs: false,
  note: ''
};

export const emptyInflectionPattern: InflectionPattern = {
  propagate: '',
  noninflectable: false,
  preferNonLemmaBase: false,
  sample: '',
  note: '',
  affixes: []
};

export const emptyInflectionPatternAffix: EditedInflectionPatternAffix = {
  advancedCriteriaId: 0,
  advancedCriteriaDescription: '',
  affix: '',
  allowOverwrite: false,
  assigned: [],
  assignedDescriptions: '',
  concatenationPlacement: 10000,
  charactersToDelete: 0,
  incompatible: [],
  incompatibleDescriptions: '',
  isPrefix: false,
  legacyId: 0,
  notes: '',
  remainsInStemmedForm: false,
  required: [],
  requiredDescriptions: '',
  phoneticCompatibilityList: ''
};

export interface AffixTest {
  id: number;
  affixGroupId: number;
  lexemeId: number;
  familyId: number;
  familyDescription?: string;
  inflectedForm: string;
  lastTestFailedString?: string;
  failReason: string;
  lastSucceeded: string;
  stem: string;
  mainLemma: string;
  requestId: string;
  _isEditing: boolean;
}

export const emptyAffixTest = {
  lexemeId: 0,
  familyId: 0,
  inflectedForm: ''
}