// Angular components
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

// Libraries components
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Angular2FontawesomeModule } from 'angular2-fontawesome';
import { NgxEchartsModule } from 'ngx-echarts';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxPopperModule } from 'ngx-popper';

// Main components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguageSettingsComponent } from './administration/language-settings/language-settings.component';

// Dialogs
import { NonBreaksForTheMarkDialogComponent } from './punctuation/non-breaks/non-breaks-for-the-mark-dialog/non-breaks-for-the-mark-dialog.component';
import { AliasListEditDialogComponent } from './phonemes/aliases/alias-list-edit-dialog/alias-list-edit-dialog.component';
import { FallBackToPhonemeDialogComponent } from './phonemes/fall-back-to-phoneme-dialog/fall-back-to-phoneme-dialog.component';
import { ChangesHaveBeenSavedDialogComponent } from './universal-components/changes-have-been-saved-dialog/changes-have-been-saved-dialog.component';
import { KnowledgeGraphDialogComponent } from './knowledge-graph/knowledge-graph-dialog/knowledge-graph-dialog.component';
import { KnowledgeGraphElementLanguageSpecificEditDialogComponent } from './knowledge-graph/knowledge-graph-element-language-specific-edit-dialog/knowledge-graph-element-language-specific-edit-dialog.component';
import { LexemeMergeDialogComponent } from './lexicon/lexeme-merge-dialog/lexeme-merge-dialog.component';
import { FamilySelectionDialogComponent } from './universal-components/family/family-selection-dialog/family-selection-dialog.component';
import { AdvancedCriteriaEditDialogComponent } from './lexicon/advanced-criteria-edit-dialog/advanced-criteria-edit-dialog.component';
import { AdvancedCriteriaDialogComponent } from './lexicon/advanced-criteria-dialog/advanced-criteria-dialog.component';
import { InflectionEditDialogComponent } from './inflection-table/inflection-edit-dialog/inflection-edit-dialog.component';
import { AffixTestListDialogComponent } from './morphology/tagging-and-inflection/affix-test-list-dialog/affix-test-list-dialog.component';
import { AffixTestAddDialogComponent } from './morphology/tagging-and-inflection/affix-test-add-dialog/affix-test-add-dialog.component';

// Services
import { ApiClientService } from './shared/services/api-client.service';
import { SessionService } from './shared/services/session.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { LanguageService } from './shared/services/language.service';
import { StatisticsService } from './shared/services/statistics.service';
import { LinguistService } from './shared/services/linguist.service';
import { TitleService } from './shared/services/title.service';
import { PunctuationService } from './shared/services/punctuation.service';
import { NonBreaksService } from './shared/services/nonbreaks.service';
import { PhonemesService } from './shared/services/phonemes.service';
import { FeaturesService } from './shared/services/features.service';
import { ScrollContentService } from './shared/services/scroll-content.service';
import { RouterAccompaniment } from './shared/services/router-accompaniment.service';
import { KnowledgeGraphService } from './shared/services/knowledge-graph.service';
import { RangeService } from './shared/services/range.service';
import { LexiconService } from './shared/services/lexicon.service';
import { InflectionTableService } from './shared/services/inflection-table.service';
import { ExtraHypothesisService } from './shared/services/extra-hypothesis.service';
import { InterfixesService } from './shared/services/interfixes.service';
import { CacheService } from './shared/services/cache.service';
import { TestsetService } from './shared/services/testset.service';
import { MiscellaneousService } from './shared/services/misc.service';
import { AdministrationService } from './shared/services/administration.service';
import { AboutService } from './shared/services/about.service';
import { HealthcheckService } from './shared/services/health-check.service';

// Guards
import {
  PassAuthenticatedUserGuard, PassEverybodyExceptGuestGuard,
  PassNonAuthenticatedUserGuard
} from './shared/services/authentication-guard.service';

// Resolvers
import { GetIdFromRouterParametersResolver } from './shared/resolvers/get-id-from-route-parameters.resolver';
import { PunctuationSignListResolver } from './shared/resolvers/punctuation-sign-list.resolver';
import { PunctuationSignDataResolver } from './shared/resolvers/punctuation-sign-data.resolver';
import { GetValueFromRouterParametersResolver } from './shared/resolvers/get-value-from-route-parameters.resolver';
import { GetTypeFromRouterParametersResolver } from './shared/resolvers/get-type-from-route-parameters.resolver';

// Directives


// Helpers
import { RouterHelper } from './shared/helpers/router.helper';
import { CookieHelper } from './shared/helpers/cookie.helper';
import { LocalStorageHelper } from './shared/helpers/localhost.helper';

// Modules
import { SharedModule } from './shared/_module/shared.module';
import { FilterModule } from './universal-components/filter/filter.module';
import { FeaturesModule } from './universal-components/features/features.module';
import { UniversalLanguageSelectModule } from './universal-components/universal-language-select/universal-language-select.module';
import { AreYouSureDialogModule } from './universal-components/are-you-sure-dialog/are-you-sure-dialog.module';
import { DirectivesModule } from './shared/directives/directives.module';

// Application components
import { LoginPageComponent } from './basic/login-page/login-page.component';
import { RootPageComponent } from './basic/root-page/root-page.component';

import { HeaderComponent } from './basic/header/header.component';

import { SidebarMenuComponent } from './basic/sidebar-menu/sidebar-menu.component';
import { LexiconComponent } from './lexicon/lexicon.component';
import { LanguageSelectionComponent } from './basic/language-selection/language-selection.component';
import { StatisticsComponent } from './basic/statistics/statistics.component';

import { PunctuationMenuComponent } from './punctuation/punctuation-menu/punctuation-menu.component';

import { HeaderMenuComponent } from './basic/header-menu/header-menu.component';
import { LanguageAndStatisticMenuComponent } from './basic/language-and-statistic-menu/language-and-statistic-menu.component';
import { NonBreaksTableForTheMarkComponent } from './punctuation/non-breaks/non-breaks-table-for-the-mark/non-breaks-table-for-the-mark.component';
import { PunctuationEditComponent } from './punctuation/punctuation-edit/punctuation-edit.component';

import { PhonemesMenuComponent } from './phonemes/phonemes-menu/phonemes-menu.component';
import { PhonemesTableComponent } from './phonemes/phonemes-table/phonemes-table.component';
import { PhonemesPositionComponent } from './phonemes/phonemes-position/phonemes-position.component';
import { PhonemesEditComponent } from './phonemes/phonemes-edit/phonemes-edit.component';

import { AliasListEditComponent } from './phonemes/aliases/alias-list-edit/alias-list-edit.component';
import { CustomTooltipComponent } from './universal-components/custom-tooltip/custom-tooltip.component';

import { FeatureLocalizationMenuComponent } from './administration/feature-localization-menu/feature-localization-menu.component';
import { FeatureLocalizationEditComponent } from './administration/feature-localization-edit/feature-localization-edit.component';
import { WelcomePageComponent } from './basic/welcome-page/welcome-page.component';
import { ErrorPageComponent } from './basic/error-page/error-page.component';
import { MainListModeComponent } from './knowledge-graph/knowledge-graph-table-element/knowledge-graph-table-element.component';
import { KnowledgeGraphMenuComponent } from './knowledge-graph/knowledge-graph-menu/knowledge-graph-menu.component';
import { KnowledgeGraphLinksListComponent } from './knowledge-graph/knowledge-graph-links-list/knowledge-graph-links-list.component';
import { KnowledgeGraphElementAdvancedEditComponent } from './knowledge-graph/knowledge-graph-element-advanced-edit/knowledge-graph-element-advanced-edit.component';
import { KnowledgeGraphElementLanguageSpecificEditComponent } from './knowledge-graph/knowledge-graph-element-language-specific-edit/knowledge-graph-element-language-specific-edit.component';
import { KnowledgeGraphLexemeSelectionComponent } from './knowledge-graph/knowledge-graph-lexeme-selection/knowledge-graph-lexeme-selection.component';
import { FamilySelectionComponent } from './universal-components/family/family-selection/family-selection.component';
import { ChangeableListComponent } from './knowledge-graph/changeable-list/changeable-list.component';
import { DefinitionMenuItemComponent } from './administration/definition-menu-item/definition-menu-item.component';
import { FeatureCategoryEditComponent } from './administration/feature-category-edit/feature-category-edit.component';
import { FeatureEditComponent } from './administration/feature-edit/feature-edit.component';
import { RangesDefinitionsComponent } from './administration/ranges/ranges-definitions/ranges-definitions.component';
import { RangeEditComponent } from './administration/ranges/range-edit/range-edit.component';
import { RangeLocalizationDialogComponent } from './administration/ranges/range-localization-dialog/range-localization-dialog.component';
import { RangeLocalizationTableComponent } from './administration/ranges/range-localization-table/range-localization-table.component';
import { RangeLookupDialogComponent } from './administration/ranges/range-lookup-dialog/range-lookup-dialog.component';
import { StatusNoteComponent } from './administration/status-note/status-note.component';
import { LexiconElementComponent } from './lexicon/lexicon-element/lexicon-element.component';
import { LexemeEditComponent } from './lexicon/lexeme-edit/lexeme-edit.component';
import { LexemeFamilyConnectionEditComponent } from './lexicon/lexeme-family-connection-edit/lexeme-family-connection-edit.component';
import { LexemeMergeComponent } from './lexicon/lexeme-merge/lexeme-merge.component';
import { AdvancedCriteriaComponent } from './lexicon/advanced-criteria/advanced-criteria.component';
import { AdvancedCriteriaEditComponent } from './lexicon/advanced-criteria-edit/advanced-criteria-edit.component';
import { PhoneticCompatibilitiesTableComponent } from './lexicon/tables/phonetic-compatibilities-table/phonetic-compatibilities-table.component';
import { HypernymTableComponent } from './lexicon/tables/hypernym-table/hypernym-table.component';
import { RequiredPhrasesTableComponent } from './lexicon/tables/required-phrases-table/required-phrases-table.component';
import { RootLookupComponent } from './lexicon/root-lookup/root-lookup.component';
import { RootLookupDialogComponent } from './lexicon/root-lookup-dialog/root-lookup-dialog.component';
import { InflectionTableComponent } from './inflection-table/inflection-table.component';
import { DoYouSaveDialogComponent } from './universal-components/do-you-save-dialog/do-you-save-dialog.component';
import { InflectionMatchedDialogComponent } from './inflection-table/inflection-matched-dialog/inflection-matched-dialog.componen';
import { ExtraHypothesisComponent } from './morphology/extra-hypothesis/extra-hypothesis.component';
import { ExtraHypothesisEditComponent } from './morphology/extra-hypothesis/extra-hypothesis-edit/extra-hypothesis-edit.component';
import { InterfixListComponent } from './morphology/interfix-module/interfix-list/interfix-list.component';
import { InterfixEditComponent } from './morphology/interfix-module/interfix-edit/interfix-edit.component';
import { InterfixAndHypothesisEditComponent } from './morphology/interfix-and-hypothesis-common/interfix-and-hypothesis-edit/interfix-and-hypothesis-edit.component';
import { InterfixAndHypothesisListComponent } from './morphology/interfix-and-hypothesis-common/interfix-and-hypothesis-list/interfix-and-hypothesis-list.component';
import { PunctuationLookupComponent } from './punctuation/punctuation-lookup/punctuation-lookup.component';
import { VisualTracerComponent } from './visual-tracer/tracer.component';
import { TestResultsComponent } from './results/testresults.component';
import { CorporaListComponent } from './corpora/corpora-list.component';
import { CorporaEditComponent } from './corpora/corpora-edit/corpora-edit.component';
import { ResultReviewComponent } from './corpora/review/result-review.component';
import { TestProviderComponent } from './testproviders/testprovider-list.component';
import { TestproviderEditComponent } from './testproviders/edit/testprovider-edit.component';
import { TestfragmentEditComponent } from './corpora/testfragment-edit/testfragment-edit.component';
import { TestConsoleComponent } from './test-console/testconsole.component';
import { NonbreakListComponent } from './non-break/nonbreak-list.component';
import { TisanePhraseFilter } from './shared/pipes/tisane-phrase-filter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AuditTrailModule } from './universal-components/audit-trail/audit-trail.module';
import { LinguistActivitiesComponent } from './administration/linguist-activities/linguist-activities.component';
import { LexemeEditDialogComponent } from './lexicon/lexeme-edit-dialog/lexeme-edit-dialog.component';
import { ResultReviewTransformationComponent } from './corpora/review-transformation/result-review-transformation.component';
import { RestartLampServiceComponent } from './administration/restart-lamp-service/restart-lamp-service.component';
import { CommonSenseCuesService } from './shared/services/common-cues.service';
import { AboutComponent } from './about/about.component';
import { UnknownWordsDialogComponent } from './corpora/unknown-words-dialog/unknown-words-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DomainGenerationDialogComponent } from './knowledge-graph/domain-generation-dialog/domain-generation-dialog.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/app/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AdvancedCriteriaEditComponent,
    AdvancedCriteriaEditDialogComponent,
    RootPageComponent,
    HeaderComponent,
    SidebarMenuComponent,
    LexiconComponent,
    HeaderMenuComponent,
    LanguageSelectionComponent,
    StatisticsComponent,
    LanguageAndStatisticMenuComponent,
    PunctuationMenuComponent,
    NonBreaksTableForTheMarkComponent,
    PunctuationEditComponent,
    NonBreaksForTheMarkDialogComponent,
    PhonemesMenuComponent,
    PhonemesTableComponent,
    PhonemesPositionComponent,
    PhonemesEditComponent,
    FallBackToPhonemeDialogComponent,
    AliasListEditComponent,
    AliasListEditDialogComponent,
    LanguageSettingsComponent,
    ChangesHaveBeenSavedDialogComponent,
    CustomTooltipComponent,
    FeatureLocalizationMenuComponent,
    FeatureLocalizationEditComponent,
    WelcomePageComponent,
    ErrorPageComponent,
    MainListModeComponent,
    KnowledgeGraphMenuComponent,
    KnowledgeGraphLinksListComponent,
    KnowledgeGraphElementAdvancedEditComponent,
    KnowledgeGraphElementLanguageSpecificEditComponent,
    KnowledgeGraphLexemeSelectionComponent,
    KnowledgeGraphDialogComponent,
    DomainGenerationDialogComponent,
    FamilySelectionComponent,
    ChangeableListComponent,
    KnowledgeGraphElementLanguageSpecificEditDialogComponent,
    DefinitionMenuItemComponent,
    FeatureCategoryEditComponent,
    FeatureEditComponent,
    RangesDefinitionsComponent,
    RangeEditComponent,
    LinguistActivitiesComponent,
    RestartLampServiceComponent,
    RangeLocalizationDialogComponent,
    RangeLocalizationTableComponent,
    RangeLookupDialogComponent,
    StatusNoteComponent,
    LexiconElementComponent,
    LexemeEditComponent,
    LexemeEditDialogComponent,
    LexemeFamilyConnectionEditComponent,
    LexemeMergeComponent,
    LexemeMergeDialogComponent,
    FamilySelectionDialogComponent,
    AdvancedCriteriaComponent,
    AdvancedCriteriaDialogComponent,
    PhoneticCompatibilitiesTableComponent,
    HypernymTableComponent,
    RequiredPhrasesTableComponent,
    RootLookupComponent,
    RootLookupDialogComponent,
    InflectionTableComponent,
    DoYouSaveDialogComponent,
    InflectionMatchedDialogComponent,
    InflectionEditDialogComponent,
    UnknownWordsDialogComponent,
    ExtraHypothesisComponent,
    ExtraHypothesisEditComponent,
    InterfixListComponent,
    InterfixEditComponent,
    InterfixAndHypothesisEditComponent,
    InterfixAndHypothesisListComponent,
    PunctuationLookupComponent,
    VisualTracerComponent,
    TestResultsComponent,
    CorporaListComponent,
    CorporaEditComponent,
    ResultReviewComponent,
    ResultReviewTransformationComponent,
    TestfragmentEditComponent,
    TestProviderComponent,
    TestproviderEditComponent,
    TestConsoleComponent,
    NonbreakListComponent,
    TisanePhraseFilter,
    AffixTestListDialogComponent,
    AffixTestAddDialogComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatSliderModule,
    MatTabsModule,
    MatCardModule,
    MatChipsModule,
    MatTooltipModule,
    MatDividerModule,
    MatSlideToggleModule,
    Angular2FontawesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AreYouSureDialogModule,
    Ng5SliderModule,
    NgxPopperModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    NgxEchartsModule,
    DirectivesModule,
    FilterModule,
    AuditTrailModule,
    FeaturesModule,
    UniversalLanguageSelectModule,
    InfiniteScrollModule,
    AppRoutingModule
  ],
  providers: [
    FormBuilder,
    MediaMatcher,
    MatGridListModule,
    SessionService,
    RouterHelper,
    CookieHelper,
    LocalStorageHelper,
    AuthenticationService,
    LanguageService,
    StatisticsService,
    LinguistService,
    TitleService,
    PunctuationService,
    NonBreaksService,
    ApiClientService,
    GetIdFromRouterParametersResolver,
    GetValueFromRouterParametersResolver,
    GetTypeFromRouterParametersResolver,
    PunctuationSignListResolver,
    PunctuationSignDataResolver,
    PhonemesService,
    FeaturesService,
    ScrollContentService,
    RouterAccompaniment,
    PassAuthenticatedUserGuard,
    PassNonAuthenticatedUserGuard,
    PassEverybodyExceptGuestGuard,
    KnowledgeGraphService,
    CommonSenseCuesService,
    RangeService,
    LexiconService,
    InflectionTableService,
    ExtraHypothesisService,
    InterfixesService,
    CacheService,
    TestsetService,
    MiscellaneousService,
    AdministrationService,
    AboutService,
    HealthcheckService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NonBreaksForTheMarkDialogComponent,
    FallBackToPhonemeDialogComponent,
    AliasListEditDialogComponent,
    ChangesHaveBeenSavedDialogComponent,
    KnowledgeGraphDialogComponent,
    DomainGenerationDialogComponent,
    KnowledgeGraphElementLanguageSpecificEditComponent,
    KnowledgeGraphElementLanguageSpecificEditDialogComponent,
    RangeLocalizationDialogComponent,
    RangeLocalizationTableComponent,
    RangeLookupDialogComponent,
    StatusNoteComponent,
    LexemeMergeDialogComponent,
    LexemeMergeComponent,
    FamilySelectionDialogComponent,
    FamilySelectionComponent,
    AdvancedCriteriaDialogComponent,
    AdvancedCriteriaEditDialogComponent,
    RootLookupDialogComponent,
    DoYouSaveDialogComponent,
    InflectionMatchedDialogComponent,
    InflectionTableComponent,
    InflectionEditDialogComponent,
    UnknownWordsDialogComponent,
    PunctuationLookupComponent,
    LexemeEditDialogComponent,
    AffixTestListDialogComponent,
    AffixTestAddDialogComponent
  ]
})
export class AppModule {
}
