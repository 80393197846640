import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

import {AuthenticationService} from '../../shared/services/authentication.service';
import {RouterHelper} from '../../shared/helpers/router.helper';

import {RouterAccompaniment} from '../../shared/services/router-accompaniment.service';
import {SessionService} from '../../shared/services/session.service';
import {Language} from '../../shared/models/language';
import {LocalStorageHelper} from '../../shared/helpers/localhost.helper';
import {LanguageService} from '../../shared/services/language.service';
import {Translated} from '../../shared/classes/translated.class';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.less']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  public credentialsForm: FormGroup;
  public isLoginError: boolean;
  public doesUserTrySubmitForm: boolean = false;
  public loginProgress: boolean = false;
  private sessionSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private routerHelper: RouterHelper,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private router: Router
  ) {}

  ngOnInit() {
    // Subscribe to session changes
    this.sessionSubscription = this.sessionService.currentSessionSource.subscribe(session => {
      if (session && session.isAuthenticated) {
        this.router.navigate(['/welcome']);
        return;
      }
    });
    
    this.createForm();
    this.hideErrorMessage();
  }

  ngOnDestroy() {
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }
  }

  private createForm(): void {
    this.credentialsForm = this.formBuilder.group({
      loginData: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public login(): Promise<void> {
    if (this.isFormValid()) {
      this.loginProgress = true;
      return this.authenticationService.login(
        this.credentialsForm.value.loginData as string,
        this.credentialsForm.value.password as string).then((isSuccessfulAuthentication) => {
        this.loginProgress = false;

        if (isSuccessfulAuthentication) {
          this.routerHelper.back();
          //this.routerHelper.navigateToWelcomePage();
        } else {
          this.showErrorMessage();
        }
      }, (error) => {
        this.loginProgress = false;
        console.error(error);

        this.showErrorMessage();
      }).catch((error) => {
        this.loginProgress = false;
        console.error(error);

        this.showErrorMessage();
      });
    }
  }

  public showErrorMessage(): void {
    this.isLoginError = true;
  }

  public hideErrorMessage(): void {
    this.isLoginError = false;
  }

  public isFormValid(): boolean {
    const loginData = this.credentialsForm.get('loginData');
    const password = this.credentialsForm.get('password');
    return this.credentialsForm.valid && 
           loginData && loginData.value && 
           password && password.value;
  }

  public checkIsFormValid(): void {
    this.doesUserTrySubmitForm = true;
  }

  public onInputClick(): void {
    this.doesUserTrySubmitForm = false;
  }

  public signInAsGuest(): Promise<void> {

    this.loginProgress = true;
    return this.authenticationService.loginAsAGuest().then((isSuccessfulAuthentication) => {
      this.loginProgress = false;

      if (isSuccessfulAuthentication) {
        this.routerHelper.back();
      } else {
        this.showErrorMessage();
      }
    }, (error) => {
      this.loginProgress = false;
      console.error(error);

      this.showErrorMessage();
    }).catch((error) => {
      this.loginProgress = false;
      console.error(error);

      this.showErrorMessage();
    });
  }
}
