import {Directive, HostListener, Output, EventEmitter, Input} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

import {AreYouSureDialogComponent} from './are-you-sure-dialog.component';

@Directive({selector: '[appOpenConfirmDialog]'})

export class ButtonOpensConfirmDialogDirective {
  @Input() enabled = true;
  @Input() title: string;
  @Input() description: string;
  @Input() subDescription: string;
  @Input() btnYesText: string;
  @Input() btnNoText: string;
  @Output() doesUserConfirmEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click', ['$event']) openConfirmDialog() {
    if (!this.enabled) {
      this.doesUserConfirmEvent.emit(true);
      return;
    }

    event.stopImmediatePropagation();
    const dialogRef: MatDialogRef<any> = this.dialog.open(AreYouSureDialogComponent,{
      maxWidth: this.isMobileView ? '99%' : '90%',
      data: {
        title: this.title,
        description: this.description,
        subDescription: this.subDescription,
        btnYesText: this.btnYesText,
        btnNoText: this.btnNoText,
      }
    });

    dialogRef.beforeClose().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });

    dialogRef.afterClosed().subscribe(doesUserConfirm => {
      this.doesUserConfirmEvent.emit(doesUserConfirm);
    });
  }
}
