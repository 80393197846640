import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';

import {ApiClientService} from './api-client.service';
import {Healthcheck} from '../models/health-check-definition.model';
import {LampUpdateResponse} from '../models/common';

@Injectable()
export class HealthcheckService {

  constructor(private apiClientService: ApiClientService) {
  }

  public getHealthcheckById(id: number): Promise<Healthcheck> {
    return this.apiClientService
      .get(`${EndpointUrls.healthcheck.healthcheck}?id=${id}`)
      .then((healthcheck: object) => {
        return (healthcheck as Healthcheck);
      });
  }

  public getHealthCheckList(type: string, argument: string): Promise<Healthcheck[]> {
    return this.apiClientService
      .get(`${EndpointUrls.healthcheck.healthcheckList}?arg=${argument}&type=${type}`)
      .then((healthcheckList) => {
        return (healthcheckList as Healthcheck[]);
      });
  }

  public updateHealthCheck(healthcheck: Healthcheck): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.healthcheck.healthcheck, healthcheck)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public deleteHealthcheckById(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.healthcheck.healthcheck}?id=${id}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public check(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .patch(`${EndpointUrls.healthcheck.healthcheck}?id=${id}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public notify(healthcheck: number, notification: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(`${EndpointUrls.healthcheck.notify}`, { healthcheck, notification })
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public createHealthcheck(healthcheck: Healthcheck): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.healthcheck.healthcheck, healthcheck)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

}
