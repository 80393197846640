import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionService } from "../shared/services/session.service";
import { Translated } from "../shared/classes/translated.class";
import { LocalStorageHelper } from "../shared/helpers/localhost.helper";
import { ActivatedRoute } from "@angular/router";
import { AboutService } from "../shared/services/about.service";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.less"],
})
export class AboutComponent extends Translated implements OnInit {
  public showSpinner: boolean;

  public lampVersion: string;
  public lampLibraries: { Key: string, Value: string }[];
  public tisaneVersion: string;
  public tisaneLibraries: { Key: string, Value: string }[];

  public currentYear = new Date().getFullYear();
  constructor(
    protected translateService: TranslateService,
    public sessionService: SessionService,
    protected localStorageHelper: LocalStorageHelper,
    protected aboutService: AboutService,
  ) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngOnInit(): void {
    this.showSpinner = true;

    Promise.all([
      this.aboutService.getVersion(),
      this.aboutService.getLibraryInfo(),
      this.aboutService.getRuntimeVersion(),
      this.aboutService.getRuntimeLibraryInfo(),
    ]).then((results) => {
      this.showSpinner = false;

      this.lampVersion = results[0];
      this.lampLibraries = results[1];
      this.tisaneVersion = results[2];
      this.tisaneLibraries = results[3];
    })
      .catch((error) => {
        this.showSpinner = false;
        console.error(error);
      });
  }

  initThisSubtypeFromSession(): void {
  }
}
