<ul>
    <ng-container *ngFor="let prop of parsedDiff | keyvalue">
        <li *ngIf="prop.value != undefined && prop.value.length != 0 && prop.value != 'verified' && prop.value">
            <ng-container *ngIf="!isObject(prop.value) && !isArray(prop.value)">
                <strong>{{prop.key}}</strong>: {{prop.value}}
            </ng-container>

            <ng-container *ngIf="isObject(prop.value) || isArray(prop.value)">
                <details>
                    <summary><strong>{{prop.key}}</strong></summary>

                    <ng-container *ngIf="prop.value?.added">
                        <div class="added">
                            <strong><small>Added: </small></strong>
                            <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: prop.value.added }"></ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="prop.value?.removed">
                        <div class="removed">
                            <strong><small>Removed: </small></strong>
                            <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: prop.value.removed }"></ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="prop.value?.modified">
                        <div class="modified">
                            <strong><small>Modified: </small></strong>
                            <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: prop.value.modified }"></ng-container>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="prop.value?.from || prop.value?.to">
                        <div *ngIf="prop.value.from !== undefined">
                            <strong><small>From: </small></strong><del>{{prop.value.from}}</del>
                            <strong style="margin-left: 5px;"><small>To: </small></strong>{{prop.value.to}}
                        </div>
                        <div *ngIf="prop.value.from === undefined">
                            {{prop.value.to}}
                        </div>
                    </ng-container>

                    <ng-container *ngIf="prop.key === 'example' && isString(prop.value)">
                        <ul>
                            <li *ngFor="let line of splitExample(prop.value)">
                                {{line}}
                            </li>
                        </ul>
                    </ng-container>

                    <ng-container *ngIf="isObject(prop.value) && !hasSpecialProperties(prop.value)">
                        <ul>
                            <ng-container *ngFor="let item of prop.value | keyvalue">
                                <li>
                                    <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item }"></ng-container>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </details>
            </ng-container>
        </li>
    </ng-container>
</ul>

<ng-template #recursiveList let-value>
    <ng-container *ngIf="value?.key !== undefined && (!isObject(value.value) && !isArray(value.value))">
        <strong>{{value.key}}</strong>: {{value.value}}
    </ng-container>

    <ng-container *ngIf="value?.key !== undefined && (isObject(value.value) || isArray(value.value))">
        <details>
            <summary><strong>{{value.key}}</strong></summary>
            <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: value.value }"></ng-container>
        </details>
    </ng-container>

    <ng-container *ngIf="!isObject(value) && !isArray(value) && value?.key === undefined">
        {{value}}
    </ng-container>

    <ng-container *ngIf="isArray(value)">
        <ul>
            <li *ngFor="let item of value">
                <ng-container *ngIf="isObject(item) && item.identity">
                    <details>
                        <summary><strong>ID: {{item.identity}}</strong></summary>
                        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: omitIdentity(item) }"></ng-container>
                    </details>
                </ng-container>
                <ng-container *ngIf="!isObject(item) || !item.identity">
                    <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item }"></ng-container>
                </ng-container>
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="isObject(value) && !isArray(value) && value?.key === undefined">
        <ul>
            <ng-container *ngFor="let item of value | keyvalue">
                <li>
                    <details>
                        <summary><strong>{{item.key}}</strong></summary>
                        <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item.value }"></ng-container>
                    </details>
                </li>
            </ng-container>
        </ul>
    </ng-container>
</ng-template>