<h2 mat-dialog-title> {{(data && data.title || 'are-you-sure.are-you-sure') | translate}}</h2>
<mat-dialog-content>
  {{(data && data.description || 'are-you-sure.you-could-not-revert-this-action') | translate}}
  {{(data && data.subDescription !== undefined ? data.subDescription : 'are-you-sure.are-you-sure') | translate}}
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-between;" class="ti-form__button-panel">
  <button mat-button [mat-dialog-close]="true">{{ data.btnYesText || ('are-you-sure.yes-continue-please' | translate) }}</button>
  <button mat-button mat-raised-button color="accent" [mat-dialog-close]="false">{{ data.btnNoText || ('COMMON.no-cancel' | translate)}}</button>
</mat-dialog-actions>

