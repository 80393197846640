<mat-accordion class="ti-panel__headers-align">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon class="ti-icon">restore</mat-icon>
        {{'EDITS.audit-trail' | translate}}
      </mat-panel-title>
      <mat-panel-description>
        {{'EDITS.audit-trail-description' | translate}}        
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="ti-form" *ngIf="editLog">
      <table mat-table [dataSource]="editLog" style="padding:3px;table-layout: fixed;">
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.username' | translate}} </th>
          <td mat-cell fxFlex="0 0 8%" style="padding:3px;" *matCellDef="let element"> {{element.username}} </td>
        </ng-container>
        <ng-container matColumnDef="datetime">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.time' | translate}} </th>
          <td mat-cell fxFlex="0 0 8%" style="padding:3px;" *matCellDef="let element"> {{element.datetime.replace('T','
            ')}}
          </td>
        </ng-container>
        <ng-container matColumnDef="original">
          <th mat-header-cell *matHeaderCellDef> {{'COMMON.original' | translate}} </th>
          <td mat-cell *matCellDef="let element" fxFlex="0 0 20%"
            style="flex: 0 0 250px;word-wrap:break-word;white-space: normal;max-width:150px;">
            <mat-expansion-panel *ngIf="element.parsedOriginal">
              <mat-expansion-panel-header>...</mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <p *ngFor="let item of element.parsedOriginal | keyvalue">
                  <strong>{{item.key}}</strong>:&nbsp;<span>{{displayProperty(item.value)}}</span>
                </p>
              </ng-template>
            </mat-expansion-panel>
            <span *ngIf="!element.parsedOriginal && element.original"
              style="white-space: nowrap;overflow:hidden;text-overflow: ellipsis;"
              matTooltip="{{'[' + element.parseError + ']    ' + element.original}}">{{shortened(element.original)}}</span>
            <span *ngIf="!element.parsedOriginal && !element.original">&nbsp;</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="requestBody">
          <th mat-header-cell *matHeaderCellDef> {{'COMMON.details' | translate}} </th>
          <td mat-cell *matCellDef="let element; let i = index" fxFlex="0 0 20%" style="padding: 3px; word-wrap:break-word">
            <mat-expansion-panel *ngIf="element.parsedRequestBody">
              <mat-expansion-panel-header>...</mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <!-- <p *ngFor="let item of element.changes | keyvalue">
                  <strong>{{item.key}}</strong>:&nbsp;<span [innerHTML]="displayProperty(item.value)"></span>
                </p> -->
                <pre [id]="'index_' + i"></pre>
              </ng-template>
            </mat-expansion-panel>
            <span *ngIf="!element.parsedRequestBody && element.requestBody"
              style="white-space: nowrap;overflow:hidden;text-overflow: ellipsis;"
              matTooltip="{{'[' + element.parseError + ']    ' + element.requestBody}}">{{shortened(element.requestBody)}}</span>
            <span *ngIf="!element.parsedRequestBody && !element.requestBody">&nbsp;</span>
          </td>
        </ng-container>

        <!-- Diff -->
        <ng-container matColumnDef="diff-old">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.differences' | translate}} </th>
          <td mat-cell *matCellDef="let element" fxFlex="0 0 20%"
            style="flex: 0 0 250px;word-wrap:break-word;white-space: normal;max-width:150px;">
            
            <mat-expansion-panel *ngIf="element.diffs">
              <mat-expansion-panel-header>...</mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ul *ngFor="let diff of element.diffs | keyvalue">
                  <li *ngFor="let sub of diff.value | keyvalue">
                    <ng-container *ngTemplateOutlet="diffUI; context { key: sub.key, changeDetail: sub.value }"></ng-container>
                  </li>
                </ul>
              </ng-template>
            </mat-expansion-panel>

          </td>
        </ng-container>

        <ng-container matColumnDef="diff">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.differences' | translate}} </th>
          <td mat-cell *matCellDef="let element" fxFlex="0 0 20%"
            style="flex: 0 0 250px;word-wrap:break-word;white-space: normal;max-width:150px;">
            
            <mat-expansion-panel *ngIf="element.diff || element.parsedRequestBody">
              <mat-expansion-panel-header>...</mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ng-container *ngTemplateOutlet="diffUIV2; context { diff: element.diff || element.parsedRequestBody }"></ng-container>
              </ng-template>
            </mat-expansion-panel>

          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> {{'EDITS.action' | translate}} </th>
          <td mat-cell fxFlex="0 0 5%" style="padding:10px;" *matCellDef="let element"> {{element.action}} </td>
        </ng-container>
        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell fxFlex="0 0 2%" style="padding:3px;" *matCellDef="let element">
            <a mat-stroked-button [disabled]="!element.parsedRequestBody" [matTooltip]="element.parsedRequestBody ? '' : ('EDITS.cannot-revert' | translate)"
              (click)="assign(element.parsedOriginal, element.parsedRequestBody); $event.preventDefault()">{{'EDITS.revert'
              | translate}}</a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
      </table>
    </div>
  </mat-expansion-panel>
</mat-accordion>



<ng-template #diffUI let-changeDetail="changeDetail" let-key="key">
  <ng-container *ngIf="changeDetail.type == 'CONTAINER'">
    {{key}}
    <ul>
      <ng-container *ngFor="let sub of changeDetail | keyvalue">
        <li *ngIf="sub.key != 'type' && sub.key != 'value'">
          <ng-container *ngTemplateOutlet="diffUI; context { key: sub.key, changeDetail: sub.value}"></ng-container>
        </li>
      </ng-container>
    </ul>

    <ul>
      <li *ngFor="let sub of changeDetail.value | keyvalue">
        <ng-container *ngTemplateOutlet="diffUI; context { key: sub.key, changeDetail: sub.value}"></ng-container>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="changeDetail.type == 'UPDATE'">
    <strong>{{key}}: Old Value {{changeDetail.oldValue}}. New Value {{changeDetail.value}}</strong>
  </ng-container>

  <!-- {{changeDetail | json}} -->
  <ng-container *ngIf="changeDetail.type == 'ADD'">
    <span style="color: green;">{{key}}: {{changeDetail.value | json}}</span>
  </ng-container>

  <ng-container *ngIf="changeDetail.type == 'REMOVE'">
    <span style="color: red;">{{key}}: {{changeDetail.oldValue | json}}</span>
  </ng-container>

  <ng-container *ngIf="changeDetail.type == 'UNCHANGED'">
    <span style="color: gray;">{{key}}: {{changeDetail.value}}</span>
  </ng-container>
</ng-template>

<ng-template #diffUIV2 let-diff="diff">
  <ul>
    <ng-container *ngFor="let prop of diff | keyvalue">
      <li *ngIf="prop.value != undefined && prop.value.length != 0 && prop.value != 'verified' && prop.value">
        <strong>{{prop.key}}</strong>:
        <ng-container *ngIf="prop.value !== undefined && !isObject(prop.value)">
          {{prop.value}}
        </ng-container>
        <ng-container *ngIf="prop.value != undefined && (prop.value.from || prop.value.to)">
          <div *ngIf="prop.value.from">
            <strong><small>From: </small></strong><del>{{prop.value.from}}</del> <strong style="margin-left: 10px;"><small>To: </small></strong>{{prop.value.to}}
          </div>
          <div *ngIf="prop.value.from === undefined">
            {{prop.value.to}}
          </div>
        </ng-container>
        <ng-container *ngIf="prop.value && isObject(prop.value) && (!prop.value.from && !prop.value.to)">
          <ng-container *ngFor="let item of prop.value | keyvalue">
            <details>
              <summary><strong>{{item.key}}</strong></summary>
              <ng-container *ngIf="isArray(item.value) || isObject(item.value)">
                <ng-container *ngTemplateOutlet="objectDisplay; context { obj: item.value }"></ng-container>
              </ng-container>
              <ng-container *ngIf="!isArray(item.value) && !isObject(item.value)">
                {{item.value}}
              </ng-container>
            </details> 
          </ng-container>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #objectDisplay let-obj="obj">
  <ul *ngIf="isArray(obj)">
    <li *ngFor="let f of obj; index as i">
      <span *ngIf="f.index >= 0 && f.value">
        Index: {{f.index}} Value: {{f.value}} 
      </span>
      <span *ngIf="f.index == undefined || !f.value">
        <ng-container *ngIf="isObject(f) || isArray(f)">
          <ng-container *ngTemplateOutlet="diffUIV2; context { diff: f }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!isObject(f) && !isArray(f)">
          {{f}}
        </ng-container>
      </span>
    </li>
  </ul>
  <ng-container *ngIf="!isArray(obj)">
    <span *ngIf="obj.index >= 0 && obj.value">
      Index: {{obj.index}} Value: {{obj.value}} 
    </span>
    <span *ngIf="obj.index == undefined || !obj.value">
      <ng-container *ngIf="isArray(obj.value) || isObject(obj.value)">
        <ng-container *ngTemplateOutlet="diffUIV2; context { diff: obj.value }"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isArray(obj.value) && !isObject(obj.value)">
        {{obj.value}}
      </ng-container>
    </span>
  </ng-container>
</ng-template>